// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaInstagram,
  FaPinterestP,
  FaDiscord,
} from 'react-icons/fa';
// import images
import AboutImg from '../src/assets/img/about/plate.png';
import ModelWhiteImg from '../src/assets/img/model-white.png';
import ModelBlackImg from '../src/assets/img/model-black.png';
import MenuImg1 from '../src/assets/img/menu/1.png';
import MenuImg2 from '../src/assets/img/menu/2.png';
import MenuImg3 from '../src/assets/img/menu/3.png';
import MenuImg4 from '../src/assets/img/menu/4.png';
import SignatureImg from '../src/assets/img/team/signature.png';
import ChefImg from '../src/assets/img/team/chef.png';
import Avatar1 from '../src/assets/img/testimonial/avatar1.png';
import Avatar2 from '../src/assets/img/testimonial/avatar2.png';
import Avatar3 from '../src/assets/img/testimonial/avatar3.png';

export const navData = [
  { href: '/', name: 'home' },
  { href: '/', name: 'about' },
  { href: '/', name: 'menu' },
  { href: '/', name: 'team' },
  { href: '/', name: 'testimonials' },
  { href: '/', name: 'book a table' },
  { href: '/', name: 'contact' },
];

export const heroData = {
  pretitle: 'Nothing brings together like',
  title: 'Bistro Cafe',
  subtitle:
    'Bistro Cafe is a fine dining restaurant themed around its magnificent orchid garden that has a warm and a friendly set up in a serene surrounding. ' ,
  btnText: 'Find out more',
};

export const socialData = [
  { href: '/', icon: <FaYoutube /> },
  { href: '/', icon: <FaFacebookF /> },
  { href: '/', icon: <FaInstagram /> },
  { href: '/', icon: <FaPinterestP /> },
  { href: '/', icon: <FaDiscord /> },
];

export const aboutData = {
  pretitle: 'our story',
  title: 'who we are',
  subtitle:
    ' We pride ourselves on providing organic , fair trade tea, cofffee and tastefull delicacies. We work with local dairies and bakeries to source the frehest and tastiest additions to your coffee and serve you with the best meals around the world. Guess what !, YOu cna book a table with Us.',
  btnText: 'find out more',
  image: AboutImg,
};

export const menuData = {
  title: 'delicious flavour of bakeries ',
  subtitle: 'view all menu for tasty meal today',
  modelImg: ModelWhiteImg,
  btnText: 'view complete menu',
  menuItems: [
    {
      image: MenuImg1,
      name: 'Chocolate Cake',
      price: '$7.99',
      description: 'A sweet juicy burger with just enough sweet taste. This recipe is just the best you need',
    },
    {
      image: MenuImg2,
      name: 'Veggie Burger',
      price: '$9.49',
      description: 'A sweet juicy burger with just enough sweet taste. This recipe is just the best you need',
    },
    {
      image: MenuImg3,
      name: 'King Burger',
      price: '$8.50',
      description: 'A sweet juicy burger with just enough sweet taste. This recipe is just the best you need',
    },
    {
      image: MenuImg4,
      name: 'Mexican Burger',
      price: '$9.99',
      description: 'A sweet juicy burger with just enough sweet taste. This recipe is just the best you need ',
    },
  ],
};

export const teamData = {
  pretitle: 'our team',
  title: 'meet our chef',
  sub1: 'Trustworthy, well organized with an attitude of self motivation, creativity, and initiative to achieve goals. A leader and ability to build team cohesion; a good leader and  fast learner, adaptable to any situations.',
  sub2: 'A chef with 15 years experience in fine dining, gourmet catering and banquet. ',
  name: 'sara peter',
  occupation: 'executive chef',
  signatureImg: SignatureImg,
  chefImg: ChefImg,
};

export const testimonialData = {
  title: "what client's say ",
  subtitle: '1500+ statisfied clients',
  modelImg: ModelWhiteImg,
  slider: [
    {
      message:
        'You and your  wonderful staff outdid yourselves once again! Delicious meal, JUST FANTASTIC!!!.',
      image: Avatar1,
      name: 'Rick Thompson',
      occupation: 'CEO of Delightful',
    },
    {
      message:
        'You and your  wonderful staff outdid yourselves once again! Delicious meal, JUST FANTASTIC!!!.',
      name: 'John Doe',
      occupation: 'CEO of Delightful',
    },
    {
      message:
        'You and your  wonderful staff outdid yourselves once again! Delicious meal, JUST FANTASTIC!!!.',
      image: Avatar3,
      name: 'Henry A.',
      occupation: 'CEO of Delightful',
    },
  ],
};

export const reservationData = {
  title: 'booking form',
  subtitle:
    'Call (234) 8162482729 from 5AM - 11PM daily, or book online with OpenTable.Reservations required for parties of 6 or more.',
  modelImg: ModelBlackImg,
  btnText: 'make a reservation',
};

export const newsletterData = {
  title: 'join our newsletter',
  subtitle: 'Get latest news & updates in your inbox.',
  placeholder: 'Subscribe our delicious dishes',
  btnText: 'subscribe now',
};

export const footerData = {
  contact: {
    title: 'contact location',
    address: '16B Eko Pearl Tower, Victoria Island, Lagos ',
    phone: '(234)8162482729',
  },
  hours: {
    title: 'working hours',
    program: [
      {
        days: 'monday - friday',
        hours: '09:00 AM - 10:00 PM',
      },
      {
        days: 'saturday - sunday',
        hours: '09:00 AM - 11:00 PM',
      },
    ],
  },
  social: {
    title: 'social network',
    icons: [
      { href: '/', icon: <FaYoutube /> },
      { href: '/', icon: <FaFacebookF /> },
      { href: '/', icon: <FaInstagram /> },
      { href: '/', icon: <FaPinterestP /> },
      { href: '/', icon: <FaDiscord /> },
    ],
  },
};
